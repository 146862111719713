import React, { useState } from 'react'
import Layout from '../../../components/layouts'
import Banner from '../../../components/features/Banner'
import SEO from '../../../components/common/SEO';
import ReactWOW from 'react-wow'
import FeatureCards from "../../../components/features/FeatureCards"

export const query = graphql`
  query Surveys {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        siteUrl: url
        defaultImage: image
        twitterUsername
      }
    }
  }
`

export default ({ data }) => {
  const [toggler, setToggler] = useState(false)
  const lightboxProps = {
    toggler: toggler,
    setToggler: setToggler,
    sources: ["https://www.youtube.com/watch?v=xE2yXvWB038"],
  }

  const bannerProps = {
    title: 'Surveys',
    subtitle: `Do you ever wonder what workers honestly think of your organization? Well, now you don’t have to. Our Surveys component allows your workers to anonymously share their perception of your company’s core values, vision, strategy, products and services. Let them discreetly weigh in on their health and safety, privacy, satisfaction and motivation to succeed and advance in their workplace.`,
    featureName: 'behaviourObservations',
    rightButtonText: 'Why Certificate Tracking Software?',
    rightButtonSrc: 'https://www.youtube.com/watch?v=xE2yXvWB038',
    mockupImg: '/features/surveys/surveys-header-banner.png'
  }

  const seo = {
    title: 'Workhub | Free Online Employee Survey Tool Anonymous Engagement',
    description: 'Workhubs free online survey tool allows you to capture the voices & opinions of your employees. Create a culture of engagment by creating & sharing surveys with your team.',
    image: '/features/surveys/social-card.jpg',
    // pathname,
    // article
  }

  const featureIndex = [
    {
      title: "Create a Survey",
      para: "Start from one of our templates or create your own to gather feedback from workers. Post statements for workers to rate on a scale of 1-5 stars. ",
    },
    {
      title: "Track Responses",
      para: "Your team can review the rating for individual statements, or the full survey. ",
    },
    {
      title: "Frequency & Trends",
      para: "Select whether a Survey is required Quarterly, Semi-Annually, or Annually and start tracking trends. ",
    },
    {
      title: "Make Improvements",
      para: "Use the data gathered in the surveys to help your team come up with an action plan for improvements. ",
    },
    {
      title: "Answer Honestly",
      para: "Allow workers to submit surveys anonymously to gather quality feedback ratings and comments. ",
    },
  ]

  return (
    <Layout {...lightboxProps}>
      <SEO {...seo} {...data.site} />
      <Banner {...bannerProps} {...lightboxProps} />

      <main className="feature-main">

        <section className="feature-section">
          <div className="container" >
            <div className="row switch-children">

              <div className="col-lg-6 feature-img">

                <ReactWOW animation="fadeInLeft" delay="0.9s">
                  <img
                    className="feature-img-right"
                    src="/features/surveys/surveys-cartoon.png"
                    alt="Surveys Admin Computer View"
                  /></ReactWOW>

              </div>


              <div className="col-lg-6 feature-text">
                <h3>Shedding the light on blind spots.</h3>

                <p>Blind spots are issues that are known to workers and unknown to leadership. Imagine walking into your boss’s office and telling them that you wouldn’t recommend their products; or, that you don’t feel safe in the workplace. <strong>Cringe!</strong> It’s human nature to avoid offending others, and while it’s great to have team players - you miss out on valuable feedback if your employees are too shy to speak up. The Surveys component provides a discreet place for them to share their observations, and an opportunity for you to shudder (or celebrate!) in private.</p>
              </div>


            </div>
          </div>
        </section>


        <section className="feature-section">
          <div className="container" >
            <div className="row">


              <div className="col-lg-6 feature-text">

                <h3>If only everything could be this easy…</h3>

                <p>Creating a survey only takes a few minutes, and admins can either start with a template or build their own. The trick is writing questions or statements that workers can agree with if their experience is positive or disagree with if the feelings are negative. For example: ‘Employees are treated fairly regardless of race, gender, religion, sexual orientation and age’. Admins can select a frequency for each survey (quarterly, annually or semi-annually), which ones are required, who they are assigned to and how many safety points are allotted per survey.  In order to collect accurate data, workers are only able to do a survey once per period.</p>

              </div>

              <div className="col-lg-6 feature-img">

                <ReactWOW animation="fadeInRight" delay="0.9s">

                  <img
                    className="feature-img-left"
                    src="/features/surveys/surveys-admin-tablet-average.png"
                    alt="Surveys Admin Tablet View"
                  />

                </ReactWOW>

              </div>

            </div>
          </div>
        </section>



        <section className="feature-section">
          <div className="container" >
            <div className="row switch-children">

              <div className="col-lg-6 feature-img">

                <ReactWOW animation="fadeInLeft" delay="0.9s">
                  <img
                    className="feature-img-right"
                    src="/features/surveys/surveys-admin-tablet.png"
                    alt="Surveys Admin Tablet Chart View"
                  />
                </ReactWOW>

              </div>


              <div className="col-lg-6 feature-text">

                <h3>Using a 5-star system to illuminate the darkness.</h3>

                <p>Now that you have the survey all set-up your employees will be able to rate each question between 1-5 stars. Our system tracks who participates in the surveys and we protect anonymity by hiding the results until enough workers have responded. Workers can comment on each question (either anonymously or as themselves) and admins can respond, require approval or reject comments if they are deemed destructive or sensitive.  All survey data is viewable in a score chart which shows you the highest, average and lowest scores; as well as how it trends from the prior periods. All these insights provide valuable information on what’s improving and what’s trending down. </p>



              </div>
            </div>
          </div>
        </section>



        <section className="feature-section">
          <div className="container">
            <div className="row">

              <div className="col-lg-6 feature-text">

                <h3>Do you read me? Loud and clear!</h3>

                <p>On the worker side they can filter between optional and required surveys, see which ones have or need to be done, and the number of responses that have been made company wide. Workers can also see the average scores - and trends and comments made by other staff. This allows your employees to get an idea of how their colleagues are feeling, whether improvements are being made in suggested areas and gives a sense of transparency for the overall process. Your company is their company, and our Surveys component allows your employees to provide meaningful feedback in a non-intimidating space.</p>

              </div>

              <div className="col-lg-6 feature-img">

                <ReactWOW animation="fadeInLeft" delay="0.9s">
                  <img
                    className="feature-img-left"
                    src="/features/surveys/surveys-worker-mobile.png"
                    alt="Surveys Admin Mobile View"
                  />
                </ReactWOW>

              </div>


            </div>
          </div>
        </section>
      </main>

      <section className="feature-overview-container">
          <h2>Overview</h2>

          <div className="feature-overview-grid" style={{marginBottom: '60px'}}>
            {featureIndex.map(item => (
              <FeatureCards title={item.title} para={item.para} />
            ))}
          </div>
        </section>
      

    </Layout>
  )
}